import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";

function CreateUserDialog({ open, onClose }) {
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState("");

  const handleCreateUser = async () => {
    if (!newUserName || !newUserEmail || !newUserPassword) {
      notify("All fields are required.", "error", 3000);
      return;
    }

    try {
      const response = await axios.post("https://ivanchuyko.com/api/register", {
        name: newUserName,
        email: newUserEmail,
        password: newUserPassword,
      });

      if (response.status === 200) {
        setRegisterSuccess("User created successfully!");
        notify("User created successfully!", "success", 3000);
        setNewUserName("");
        setNewUserEmail("");
        setNewUserPassword("");
        onClose();
      }
    } catch (error) {
      notify("Failed to create user. Please try again.", "error", 3000);
      console.error(error);
    }
  };

  return (
    <>
      <Notification /> {/* Ensure this line is added */}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
           
          {registerSuccess && (
            <Typography variant="body1" color="success" gutterBottom>
              {registerSuccess}
            </Typography>
          )}
          <TextField
            margin="normal"
            fullWidth
            label="User Name"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleCreateUser} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateUserDialog;
