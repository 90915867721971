// CommonDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem
} from "@mui/material";

const CreateDialog = ({
  open,
  onClose,
  title,
  contentText,
  fields,
  handleInputChange,
  handleSubmit
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {fields.map((field, index) => (
                   <TextField
                   key={index}
                   autoFocus={field.autoFocus || false}
                   margin="dense"
                   label={field.label}
                   name={field.name}
                   value={field.value || ""}
                   onChange={handleInputChange}
                   fullWidth={field.fullWidth || false}
                   size="small"
                   style={field.style || {}}
                   disabled={field.disabled || false}
                   select={field.select || false}
                   sx={field.sx || {}}
                 >
                   {field.select &&
                     field.options.map((option, idx) => (
                       <MenuItem key={idx} value={option}>
                         {option}
                       </MenuItem>
                     ))}
                 </TextField>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
