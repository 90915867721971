import React, { useState, useEffect  } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import { DataGrid } from "@mui/x-data-grid";
import UpdateDialog from "../Components/UpdateDialog";
import CreateDialog from "../Components/CreateDialog";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function ItemGroups() {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({
    item_group: "",
    description: "",
    area1: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [groupToUpdate, setGroupToUpdate] = useState({
    item_group: "",
    description: "",
    area1: "",
  });

  const [areas, setAreas] = useState([]);

  useEffect(() => {
    fetch("https://localmap.space/api/areas")
      .then((response) => response.json())
      .then((data) => {
        setAreas(data.map((areaObj) => areaObj.area));
      })
      .catch((error) => console.error("Error fetching areas:", error));
  }, []);

  const fields = [
    {
      label: "Group",
      name: "group",
      value: groupToUpdate.item_group,
      fullWidth: true,
      autoFocus: true,
      disabled: true,
    },

    {
      label: "Description",
      name: "description",
      value: groupToUpdate.description,
      style: { marginRight: "8px" },
    },
    {
      label: "Storage Area 1",
      name: "area1",
      value: groupToUpdate.area1,
      style: { marginRight: "8px" },
      select: true, // Indicate this field is a dropdown
      options: areas, // Pass the options for the dropdown
      sx: { width: '200px' }
    },
  ];

  const newfields = [
    {
      label: "Group",
      name: "item_group",
      value: newGroup.item_group,
      fullWidth: true,
      autoFocus: true,
       
    },

    {
      label: "Description",
      name: "description",
      value: newGroup.description,
      style: { marginRight: "8px" },
    },
    {
      label: "Storage Area 1",
      name: "area1",
      value: newGroup.area1,
      style: { marginRight: "8px" },       
      select: true, // Indicate this field is a dropdown
      options: areas, // Pass the options for the dropdown
      sx: { width: '200px' } 
    },
  ];

  const fetchGroups = async (item_group) => {
    try {
      let response;
      if (item_group) {
        response = await axios.get(
          `https://localmap.space/api/itemgroups?group=${item_group}`
        );
      } else {
        response = await axios.get(
          "https://localmap.space/api/itemgroups"
        );
      }
      setGroups(response.data);
    } catch (error) {
      console.error("Error fetching Groups:", error);
    }
  };

  const handleUpdate = async () => {
    const { item_group, description, area1 } = groupToUpdate;
    const sql = `update item_groups set description = '${description}', area1 = '${area1}', mod_date= now() where item_group = '${item_group}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });

      // Fetch only the updated item
      const response = await axios.get(
        `https://localmap.space/api/itemgroups?group=${item_group}`
      );
      const updatedGroup = response.data[0]; // Assuming only one item matches the updated item

      // Update the items state with the updated item
      const updatedGroups = groups.map((oldGroup) =>
        oldGroup.item_group === item_group ? updatedGroup : oldGroup
      );
      setGroups(updatedGroups);

      setUpdateDialogOpen(false); // Close the update dialog
      setSelectedRow(null); // Reset the selected row
      notify("Group has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating Group:", error);
    }
  };

  const clearGroups = () => {
    notify("Groups cleared!", "success", 1000);
    setGroups([]); // Clears the items from the screen
    setSelectedRow(null); // Deselects any selected row
    setNewGroup({
      item_group: "",
      description: "",
      area1: "",
    }); // Clears the input fields
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewGroup((prevGroup) => ({
      ...prevGroup,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { item_group, description, area1 } = newGroup;
    const sql = `insert into item_groups (item_group, description, area1, mod_date) values ('${item_group}', '${description}', '${area1}', NOW())`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchGroups(); // Refresh the list after submitting the new item
      setNewGroup({
        item_group: "",
        description: "",
        area1: "",
      }); // Clear the input fields
      setCreateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error creating Group:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const group = groups[selectedRow];
      setGroupToDelete(group);
      setOpenDialog(true);
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setGroupToUpdate((prevGroup) => ({
      ...prevGroup,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setGroupToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (groupToDelete) {
      const sql = `delete from item_groups where item_group = '${groupToDelete.item_group}'`;

      try {
        await axios.post("https://localmap.space/api/query", { sql });
        fetchGroups(); // Refresh the list after deleting the item
        setSelectedRow(null); // Reset the selected row
      } catch (error) {
        console.error("Error deleting Group:", error);
      } finally {
        setOpenDialog(false);
        setGroupToDelete(null);
      }
    }
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleModify = () => {
    if (selectedRow !== null) {
      const item_group = groups[selectedRow];
      setGroupToUpdate(item_group);
      setUpdateDialogOpen(true);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const BoldHeader = styled("div")({
    fontWeight: "bold",
  });
  const CustomHeaderComponent = (props) => {
    return <BoldHeader>{props.colDef.headerName}</BoldHeader>;
  };

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Group"
          name="group"
          value={newGroup.item_group}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchGroups(newGroup.item_group)}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Find
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearGroups}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Clear
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleModify}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Modify
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Create
        </Button>
      </Box>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={groups.map((item_group, index) => ({ ...item_group, id: index }))}
          columns={[
            {
              field: "item_group",
              headerName: "Item Group",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "area1",
              headerName: "Area 1",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
          ]}
          onRowClick={(params) => handleRowClick(params.id)}
          disableSelectionOnClick
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {groupToDelete?.item_group}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <CreateDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        title="Create Group"
        contentText="Please provide Group:"
        fields={newfields}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        title="Update Item Group:"
        fields={fields}
        handleInputChange={handleUpdateInputChange}
        handleSubmit={handleUpdate}
      />
    </Container>
  );
}

export default ItemGroups;
