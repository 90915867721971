import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  CssBaseline,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./App.css";
import Login from "./Pages/Login";
import Home from "./Pages/home";
import Receiving from "./Pages/Receiving";
import ItemsScreen from "./Pages/Items";
import LocationsScreen from "./Locations";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from "@mui/icons-material/Logout";
import AreasScreen from "./Pages/Areas";
import PurchaseOrdersScreen from "./Pages/PurchaseOrders";
import ItemGroups from "./Pages/ItemGroups";
import SettingsScreen from "./Pages/Settings";
import { Notification } from "./Components/Notification";

//https://mui.com/material-ui/material-icons/?query=sett - icons

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Notification /> {/* Ensure this line is added */}
      <div className="App">
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleSidebar}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              WMS
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor="left"
          open={sidebarOpen}
          sx={{
            width: 240,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
          }}
        >
          <Toolbar />
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <List sx={{ flexGrow: 1 }}>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Inbound</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      button
                      component={Link}
                      to="/purchaseOrdersScreen"
                    >
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Purchase Orders" />
                    </ListItem>
                    <ListItem button component={Link} to="/receiving">
                      <ListItemIcon>
                        <LocalShippingIcon />
                      </ListItemIcon>
                      <ListItemText primary="Receiving" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Storage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem button component={Link} to="/areasScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Areas" />
                    </ListItem>
                    <ListItem button component={Link} to="/locationsScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Locations" />
                    </ListItem>
                    <ListItem button component={Link} to="/itemGroupsScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Item Groups" />
                    </ListItem>
                    <ListItem button component={Link} to="/itemsScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Items" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Outbound</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem button component={Link} to="/itemsScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Items" />
                    </ListItem>
                    <ListItem button component={Link} to="/locationsScreen">
                      <ListItemIcon>
                        <InventoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Locations" />
                    </ListItem>
                    <ListItem button component={Link} to="/receiving">
                      <ListItemIcon>
                        <LocalShippingIcon />
                      </ListItemIcon>
                      <ListItemText primary="Receiving" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
            <ListItem button component={Link} to="/settingsScreen">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
            ml: sidebarOpen ? 0 : -30,
            transition: "margin-left 0.3s",
            width: sidebarOpen ? "calc(100% - 240px)" : "100%",
          }}
        >
          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/receiving" element={<Receiving />} />
            <Route path="/itemsScreen" element={<ItemsScreen />} />
            <Route path="/locationsScreen" element={<LocationsScreen />} />
            <Route path="/areasScreen" element={<AreasScreen />} />
            <Route path="/settingsScreen" element={<SettingsScreen />} />
            <Route
              path="/purchaseOrdersScreen"
              element={<PurchaseOrdersScreen />}
            />
            <Route path="/itemGroupsScreen" element={<ItemGroups />} />
          </Routes>
        </Box>
      </div>
    </Router>
  );
}

export default App;
