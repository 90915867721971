import React, { useState, useEffect } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import UpdateDialog from "../Components/UpdateDialog";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CreateDialog from "../Components/CreateDialog";

function PurchaseOrdersScreen() {
  const [ponums, setPonums] = useState([]);
  const [newPonum, setNewPonum] = useState({
    ponum: "",
    vendor: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [ponumToDelete, setPonumToDelete] = useState(null);
  const [ponumToUpdate, setPonumToUpdate] = useState({
    ponum: "",
    vendor: "",
  });

  const fields = [
    {
      label: "Purchase Order",
      name: "ponum",
      value: ponumToUpdate.ponum,
      fullWidth: true,
      autoFocus: true,
      disabled: false,
    },

    {
      label: "Vendor",
      name: "vendor",
      value: ponumToUpdate.vendor,
      style: { marginRight: "8px" },
    },
  ];

  const newfields = [
    {
      label: "Purchase Order",
      name: "ponum",
      value: newPonum.ponum,
      fullWidth: true,
      autoFocus: true,
      disabled: false,
    },

    {
      label: "Vendor",
      name: "vendor",
      value: newPonum.vendor,
      style: { marginRight: "8px" },
    },
  ];

  const fetchPonums = async (ponum) => {
    try {
      let response;
      if (ponum) {
        response = await axios.get(
          `https://localmap.space/api/po?ponum=${ponum}`
        );
      } else {
        response = await axios.get("https://localmap.space/api/po");
      }
      setPonums(response.data);
    } catch (error) {
      console.error("Error fetching Purchase Order:", error);
    }
  };

  const [poLines, setPoLines] = useState([]);
  const [selectedPonum, setSelectedPonum] = useState(null);

  const fetchPoLines = async (ponum) => {
    try {
      const response = await axios.get(
        `https://localmap.space/api/poline?ponum=${ponum}`
      );
      setPoLines(response.data);
    } catch (error) {
      console.error("Error fetching PO Lines:", error);
    }
  };

  useEffect(() => {
    if (selectedPonum) {
      fetchPoLines(selectedPonum);
    }
  }, [selectedPonum]);

  const handleUpdate = async () => {
    const { ponum, vendor } = ponumToUpdate;
    const sql = `update po set vendor = '${vendor}' where ponum = '${ponum}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });

      // Fetch only the updated item
      const response = await axios.get(
        `https://localmap.space/api/po?ponum=${ponum}`
      );
      const updatedPonum = response.data[0]; // Assuming only one item matches the updated item

      // Update the items state with the updated item
      const updatedPonums = ponums.map((oldPonum) =>
        oldPonum.ponum === ponum ? updatedPonum : oldPonum
      );
      setPonums(updatedPonums);

      setUpdateDialogOpen(false); // Close the update dialog
      setSelectedRow(null); // Reset the selected row
      notify("Purchase Order has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating Purchase Order:", error);
    }
  };

  const clearPonums = () => {
    notify("Purchase Order cleared!", "success", 1000);
    setPonums([]); // Clears the PO table
    setPoLines([]); // Clears the PO lines
    setSelectedRow(null); // Deselects any selected row
    setNewPonum({
      ponum: "",
      vendor: "",
    }); // Clears the input fields
    setSelectedPonum(null); // Clears the selected PO number
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPonum((prevPonum) => ({
      ...prevPonum,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { ponum, vendor } = newPonum;
    const sql = `insert into po (ponum, vendor) values ('${ponum}', '${vendor}')`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchPonums(); // Refresh the list after submitting the new item
      setNewPonum({
        ponum: "",
        vendor: "",
      }); // Clear the input fields
      setCreateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error creating Purchase Order:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
    setSelectedPonum(ponums[index].ponum); // Update selectedPonum
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const ponum = ponums[selectedRow];
      setPonumToDelete(ponum);
      setOpenDialog(true);
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setPonumToUpdate((prevPonum) => ({
      ...prevPonum,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setPonumToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (ponumToDelete) {
      const sql = `delete from po where ponum = '${ponumToDelete.ponum}'`;

      try {
        await axios.post("https://localmap.space/api/query", { sql });
        fetchPonums();
        setSelectedRow(null);
      } catch (error) {
        console.error("Error deleting Purchase Order:", error);
      } finally {
        setOpenDialog(false);
        setPonumToDelete(null);
      }
    }
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleModify = () => {
    if (selectedRow !== null) {
      const ponum = ponums[selectedRow];
      setPonumToUpdate(ponum);
      setUpdateDialogOpen(true);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };
  const BoldHeader = styled("div")({
    fontWeight: "bold",

    textAlign: "center",
  });
  const CustomHeaderComponent = (props) => {
    return <BoldHeader>{props.colDef.headerName}</BoldHeader>;
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handlePoLineClick = (row) => {
    setSelectedPonum(row.ponum);
    setSelectedItem(row.item);
  };

  const handleDeletePoLine = async () => {
    if (selectedPonum && selectedItem) {
      const sql = `delete from polines where ponum = '${selectedPonum}' and item = '${selectedItem}'`;
      try {
        await axios.post("https://localmap.space/api/query", { sql });
        setOpenDialog(true);
        notify("PO Line deleted!", "success", 1000);
        fetchPoLines(selectedPonum); // Refresh PO Lines
      } catch (error) {
        console.error("Error deleting PO Line:", error);
      }
    }
  };

  const [createPoLineDialogOpen, setCreatePoLineDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState("");

  const handleCreatePoLine = () => {
    setCreatePoLineDialogOpen(true);
  };

  const handleCloseCreatePoLineDialog = () => {
    setCreatePoLineDialogOpen(false);
  };

  const handleItemInputChange = (event) => {
    setNewItem(event.target.value);
  };

  const handleSubmitPoLine = async () => {
    const ponum = selectedPonum;
    const item = newItem;
    const sql = `insert into polines (ponum, item) values ('${ponum}', '${item}')`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchPoLines(selectedPonum); // Refresh the list after submitting the new item
      setNewItem(""); // Clear the input field
      setCreatePoLineDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error creating PO Line:", error);
    }
  };

  const handleModifyPoLine = () => {
    setUpdateDialogOpen(true);
  };

  const handleUpdatePoLine = async () => {
    const ponum = selectedPonum;
    const item = ponumToUpdate.item;
    const sql = `update polines set item = '${item}' where ponum = '${ponum}' and item = '${selectedItem}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchPoLines(selectedPonum); // Refresh the list after submitting the update
      setUpdateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error updating PO Line:", error);
    }
  };

  const StyledDataGrid = styled(DataGrid)({
    '& .even-row': {
      backgroundColor: '#daecf2',
    },
    '& .odd-row': {
      backgroundColor: '#f0daf2',
    },
  });

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Purchase Order"
          name="ponum"
          value={newPonum.ponum}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchPonums(newPonum.ponum)}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Find
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearPonums}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Clear
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleModify}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Modify
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Create
        </Button>
      </Box>

      <Box
        sx={{
          height: 250,
          width: "100%",
          backgroundColor: "#f5f5f5",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <StyledDataGrid
          rows={ponums.map((ponum, index) => ({
            ...ponum,
            id: index,
          }))}
          columns={[
            {
              field: "ponum",
              headerName: "Purchase Order",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "vendor",
              headerName: "Vendor",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
          ]}
          onRowClick={(params) => handleRowClick(params.id)}
          disableSelectionOnClick
          rowHeight={35}
          componentsProps={{
            header: {
              style: {
                backgroundColor: "#1976d2",
                color: "#fff",
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      </Box>
      

      <Typography
        variant="h6"
        component="h2"
        sx={{ fontWeight: "bold", padding: "10px" }}
      >
        PO Lines:
      </Typography>

      <Box
        sx={{
          height: 300,
          width: "100%",
          marginTop: 2,
          backgroundColor: "#f5f5f5",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <StyledDataGrid
          rows={poLines}
          columns={[
            {
              field: "ponum",
              headerName: "Purchase Order",
              flex: 1,
            },
            {
              field: "item",
              headerName: "Item",
              flex: 1,
            },
            {
              field: "expqty",
              headerName: "Expected Quantity",
              flex: 1,
            },
          ]}
          getRowId={(row) => row.ponum + row.item}
          onRowClick={(params) => handlePoLineClick(params.row)}
          rowHeight={35}
          componentsProps={{
            header: {
              style: {
                backgroundColor: "#1976d2",
                color: "#fff",
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePoLine}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreatePoLine}
            sx={{ marginLeft: 2 }}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleModifyPoLine}
            sx={{ marginLeft: 2 }}
          >
            Modify
          </Button>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {ponumToDelete?.ponum}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <CreateDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        title="Create Purchase Order"
        contentText="Please provide Purchase Order:"
        fields={newfields}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        title="Update Purchase Order"
        fields={fields}
        handleInputChange={handleUpdateInputChange}
        handleSubmit={handleUpdate}
      />

      <CreateDialog
        open={createPoLineDialogOpen}
        onClose={handleCloseCreatePoLineDialog}
        title="Create PO Line"
        contentText="Please provide Item:"
        fields={[
          {
            label: "Item",
            name: "item",
            value: newItem,
            fullWidth: true,
            autoFocus: true,
            disabled: false,
          },
        ]}
        handleInputChange={handleItemInputChange}
        handleSubmit={handleSubmitPoLine}
      />

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        title="Update PO Line"
        fields={[
          {
            label: "Item",
            name: "item",
            value: ponumToUpdate.item,
            fullWidth: true,
            autoFocus: true,
            disabled: false,
          },
        ]}
        handleInputChange={handleUpdateInputChange}
        handleSubmit={handleUpdatePoLine}
      />
    </Container>
  );
}

export default PurchaseOrdersScreen;
