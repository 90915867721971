import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import UpdateDialog from "../Components/UpdateDialog";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CreateDialog from "../Components/CreateDialog";

function Receiving() {
  const [isQtyDisabled, setQtyDisabled] = useState(true);
  const [newReceiving, setReceiving] = useState({
    trknum: "",
    ponum: "",
    item: "",
    description: "",
    uom: "",
    qty: "",
  });
  const [isReceiveButtonDisabled, setReceiveButtonDisabled] = useState(true);
  useEffect(() => {
    const requiredFields = [newReceiving.ponum, newReceiving.item, newReceiving.qty];
    setReceiveButtonDisabled(requiredFields.some((field) => field === ""));
  }, [newReceiving]);

  const itemRef = useRef(null);
  const qtyRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReceiving((prevArea) => ({
      ...prevArea,
      [name]: value,
    }));

    if (name === "item") {
      //validateItem(value, newReceiving.ponum);
      fetchUOM(value);
      fetchDescription(value);
    }
  };

  const [validationError, setValidationError] = useState("");
  const [uomError, setUomError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [settings, setSettings] = useState([]);
  const [isPOValidationSkipped, setPOValidationSkipped] = useState(false);
  const [poValidationError, setPOValidationError] = useState("");
  const [areFieldsDisabled, setFieldsDisabled] = useState(true);
  const [isPOValidationEnabled, setPOValidationEnabled] = useState(true);

  const fetchSettings = async () => {
    try {
      const response = await axios.get("https://localmap.space/api/settings");
      setSettings(response.data);

      // Find the setting for "Skip Tracking #"
      const skipTrackingSetting = response.data.find(
        (setting) => setting.name === "Skip Tracking #"
      );

      // Find the setting for "Skip PO Validation"
      const skipPOValidationSetting = response.data.find(
        (setting) => setting.name === "Skip PO Validation"
      );

      // Check if both settings are disabled
      if (
        skipTrackingSetting &&
        skipTrackingSetting.is_enabled === 0 &&
        skipPOValidationSetting &&
        skipPOValidationSetting.is_enabled === 0
      ) {
        setFieldsDisabled(true);
      } else {
        setFieldsDisabled(false);
      }

      if (skipPOValidationSetting && skipPOValidationSetting.is_enabled === 0) {
        setPOValidationSkipped(true);
        setPOValidationEnabled(false); // PO validation is enabled
      } else {
        setPOValidationSkipped(false);
        setPOValidationEnabled(true); // PO validation is not enabled
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleTrknumChange = (event) => {
    const { value } = event.target;
    setReceiving((prevArea) => ({
      ...prevArea,
      trknum: value,
    }));

    if (value) {
      setFieldsDisabled(false);
    } else {
      setFieldsDisabled(true);
    }
  };

  const validatePO = async (ponum) => {
    const sql = `select 'x' from po where ponum = '${ponum}'`;

    try {
      const response = await axios.post("https://localmap.space/api/query", {
        sql,
      });
      if (response.data.length === 0) {
        setPOValidationError("PO number is not valid");
      } else {
        setPOValidationError("");
      }
    } catch (error) {
      console.error("PO Validation error:", error);
      setPOValidationError("Error validating PO number");
    }
  };

  const handlePOBlur = () => {
    if (!isPOValidationSkipped) {
      validatePO(newReceiving.ponum);
    }
  };

  const validateItem = async (item, ponum) => {
    const sql = `select 'x' from polines where ponum = '${ponum}' and item = '${item}'`;

    try {
      const response = await axios.post("https://localmap.space/api/query", {
        sql,
      });
      if (response.data.length === 0) {
        setValidationError("Item is not valid for the given Purchase Order");
        setQtyDisabled(true);
        itemRef.current.focus();
      } else {
        setQtyDisabled(false);
        setValidationError("");
        setTimeout(() => qtyRef.current.focus(), 0);
      }
    } catch (error) {
      console.error("Validation error:", error);
      setValidationError("Error validating item");
      setQtyDisabled(true);
      itemRef.current.focus();
      itemRef.current.focus();
    }
  };

  const fetchUOM = async (item) => {
    const sql = `select uom from items where item = '${item}'`;

    try {
      const response = await axios.post("https://localmap.space/api/query", {
        sql,
      });
      if (response.data.length > 0) {
        setReceiving((prevArea) => ({
          ...prevArea,
          uom: response.data[0].uom,
        }));
        setUomError("");
      } else {
        setUomError("UOM not found for the given item");
      }
    } catch (error) {
      console.error("Error fetching UOM:", error);
      setUomError("Error fetching UOM");
    }
  };

  const fetchDescription = async (item) => {
    const sql = `select description from items where item = '${item}'`;

    try {
      const response = await axios.post("https://localmap.space/api/query", {
        sql,
      });
      if (response.data.length > 0) {
        setReceiving((prevArea) => ({
          ...prevArea,
          description: response.data[0].description,
        }));
        setDescriptionError("");
      } else {
        setDescriptionError("Description not found for the given item");
      }
    } catch (error) {
      console.error("Error fetching Description:", error);
      setDescriptionError("Error fetching Description");
    }
  };

  const handleItemBlur = () => {
    validateItem(newReceiving.item, newReceiving.ponum);
  };

  const receiveInventory = async () => {
    const sql = `insert into inventory (item, location, qty) values ('${newReceiving.item}', '${'location'}', '${newReceiving.qty}')`;
  
    try {
      await axios.post("https://localmap.space/api/query", { sql });
      clearInputFields();
      notify("Inventory received!", "success", 3000);
    } catch (error) {
      console.error("Error creating area:", error);
    }
    
  };
  
  const clearInputFields = () => {
    setReceiving({
      trknum: newReceiving.trknum,
      ponum: newReceiving.ponum,
      item: "",
      description: "",
      uom: "",
     qty: "",
    });
    setValidationError("");
    setUomError("");
    setDescriptionError("");
    setPOValidationError("");
  };

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Tracking #"
          name="trknum"
          value={newReceiving.trknum}
          onChange={handleTrknumChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <TextField
          label="Purchase Order"
          name="ponum"
          value={newReceiving.ponum}
          onChange={handleInputChange}
          onBlur={handlePOBlur}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled={areFieldsDisabled}
          error={!!poValidationError}
          helperText={poValidationError}
        />
        <TextField
          label="Item"
          name="item"
          value={newReceiving.item}
          onChange={handleInputChange}
          onBlur={handleItemBlur}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          error={!!validationError}
          helperText={validationError}
          inputRef={itemRef}
          disabled={!isPOValidationEnabled}
        />
        <TextField
          label="Description"
          name="description"
          value={newReceiving.description}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled="1"
        />
        <TextField
          label="UOM"
          name="uom"
          value={newReceiving.uom}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          error={!!uomError}
          helperText={uomError}
          disabled="1"
        />
        <TextField
          label="Qty"
          name="qty"
          value={newReceiving.qty}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled={isQtyDisabled || areFieldsDisabled}
          inputRef={qtyRef}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={receiveInventory}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
          disabled={isReceiveButtonDisabled} 
        >
          Receive
        </Button>
      </Box>
      
      <Notification />
    </Container>
  );
}

export default Receiving;
