import React, { useState, useEffect } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import UpdateDialog from "../Components/UpdateDialog";
import CreateDialog from "../Components/CreateDialog";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function ItemsScreen() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    item: "",
    description: "",
    len: "",
    wid: "",
    hgt: "",
    weight: "",
    is_active: "",
    uom: "",
    mod_date: "",
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToUpdate, setItemToUpdate] = useState({
    item: "",
    description: "",
    len: "",
    wid: "",
    hgt: "",
    weight: "",
    is_active: "",
    uom: "",
    mod_date: "",
  });

  const fetchItems = async (item) => {
    try {
      let response;
      if (item) {
        response = await axios.get(
          `https://localmap.space/api/items?item=${item}`
        );
      } else {
        response = await axios.get("https://localmap.space/api/items");
      }
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleUpdate = async () => {
    const { item, description, len, wid, hgt, weight, is_active, uom } =
      itemToUpdate;
    const sql = `update items set description = '${description}', len = ${len}, wid = ${wid}, hgt = ${hgt}, weight = ${weight}, is_active = ${is_active},uom = '${uom}', mod_date= now() where item = '${item}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });

      // Fetch only the updated item
      const response = await axios.get(
        `https://localmap.space/api/items?item=${item}`
      );
      const updatedItem = response.data[0]; // Assuming only one item matches the updated item

      // Update the items state with the updated item
      const updatedItems = items.map((oldItem) =>
        oldItem.item === item ? updatedItem : oldItem
      );
      setItems(updatedItems);

      setUpdateDialogOpen(false); // Close the update dialog
      setSelectedRow(null); // Reset the selected row
      notify("Item has been updated", "success", 3000);
    } catch (error) {
      notify("Item has been updated", "error", 3000);
      console.error("Error updating item:", error);
    }
  };

  const clearItems = () => {
    notify("Items cleared!", "success", 1000);
    setItems([]); // Clears the items from the screen
    setSelectedRow(null); // Deselects any selected row
    setNewItem({
      item: "",
      description: "",
      len: "",
      wid: "",
      hgt: "",
      is_active: "",
      uom: "",
      mod_date: "",
    }); // Clears the input fields
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { item, description, len, wid, hgt, weight, is_active, uom } =
      newItem;
    const sql = `insert into items (item, description, len, wid, hgt,weight, is_active, uom, mod_date) values ('${item}', '${description}', ${len}, ${wid}, ${hgt},${weight}, ${is_active}, '${uom}', NOW())`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchItems(); // Refresh the list after submitting the new item
      setNewItem({
        item: "",
        description: "",
        len: "",
        wid: "",
        hgt: "",
        is_active: "",
        uom: "",
      }); // Clear the input fields
      setCreateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      notify("Please fill all fields", "error", 1000);
      console.error("Error creating item:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const item = items[selectedRow];
      setItemToDelete(item);
      setOpenDialog(true);
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setItemToUpdate((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      const sql = `delete from items where item = '${itemToDelete.item}'`;

      try {
        await axios.post("https://localmap.space/api/query", { sql });
        fetchItems(); // Refresh the list after deleting the item
        setSelectedRow(null); // Reset the selected row
      } catch (error) {
        console.error("Error deleting item:", error);
      } finally {
        setOpenDialog(false);
        setItemToDelete(null);
      }
    }
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleModify = () => {
    if (selectedRow !== null) {
      const item = items[selectedRow];
      setItemToUpdate(item);
      setUpdateDialogOpen(true);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const BoldHeader = styled("div")({
    fontWeight: "bold",
  });
  const CustomHeaderComponent = (props) => {
    return <BoldHeader>{props.colDef.headerName}</BoldHeader>;
  };

  const [uoms, setUoms] = useState([]);

  useEffect(() => {
    fetch("https://localmap.space/api/uoms")
      .then((response) => response.json())
      .then((data) => {
        setUoms(data.map((uomObj) => uomObj.uom));
      })
      .catch((error) => console.error("Error fetching UOMs:", error));
  }, []);

  const fields = [
    {
      label: "Item",
      name: "item",
      value: itemToUpdate.item,
      fullWidth: true,
      autoFocus: true,
    },

    {
      label: "Description",
      name: "description",
      fullWidth: true,
      value: itemToUpdate.description,
      style: { marginRight: "8px" },
    },
    {
      label: "Lenght",
      name: "len",
      value: itemToUpdate.len,
      style: { marginRight: "8px" },
    },
    {
      label: "Width",
      name: "wid",
      value: itemToUpdate.wid,
      style: { marginRight: "8px" },
    },
    {
      label: "Height",
      name: "hgt",
      value: itemToUpdate.hgt,
      style: { marginRight: "8px" },
    },
    {
      label: "Weight",
      name: "weight",
      value: itemToUpdate.weight,
      style: { marginRight: "8px" },
    },
    {
      label: "Active",
      name: "is_active",
      value: itemToUpdate.is_active,
      style: { marginRight: "8px" },
    },
    {
      label: "UOM",
      name: "uom",
      value: itemToUpdate.uom,
      select: true,
      options: uoms, 
      style: { marginRight: "8px" },
      sx: { width: '225px' }
    },
  ];

  const newfields = [
    {
      label: "Item",
      name: "item",
      value: newItem.item,
      fullWidth: true,
      autoFocus: true,
    },

    {
      label: "Description",
      name: "description",
      fullWidth: true,
      value: newItem.description,
      style: { marginRight: "8px" },
    },
    {
      label: "Lenght",
      name: "len",
      value: newItem.len,
      style: { marginRight: "8px" },
    },
    {
      label: "Width",
      name: "wid",
      value: newItem.wid,
      style: { marginRight: "8px" },
    },
    {
      label: "Height",
      name: "hgt",
      value: newItem.hgt,
      style: { marginRight: "8px" },
    },
    {
      label: "Weight",
      name: "weight",
      value: newItem.weight,
      style: { marginRight: "8px" },
    },
    {
      label: "Active",
      name: "is_active",
      value: newItem.is_active,
      style: { marginRight: "8px" },
    },
    {
      label: "UOM",
      name: "uom",
      value: newItem.uom,
      select: true,
      options: uoms, 
      style: { marginRight: "8px" },
      sx: { width: '225px' }
    },
  ];

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Item"
          name="item"
          value={newItem.item}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchItems(newItem.item)}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Find
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearItems}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Clear
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleModify}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Modify
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Create
        </Button>
      </Box>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={items.map((item, index) => ({ ...item, id: index }))}
          columns={[
            {
              field: "item",
              headerName: "Item",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "len",
              headerName: "Length",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "wid",
              headerName: "Width",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "hgt",
              headerName: "Height",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "weight",
              headerName: "Weight",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "is_active",
              headerName: "Active",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "uom",
              headerName: "UOM",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "mod_date",
              headerName: "Date",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
          ]}
          onRowClick={(params) => handleRowClick(params.id)}
          disableSelectionOnClick
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {itemToDelete?.item}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
 

      <CreateDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        title="Create Area"
        contentText="Please provide Area:"
        fields={newfields}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        title="Update Area"
        fields={fields}
        handleInputChange={handleUpdateInputChange}
        handleSubmit={handleUpdate}
      />
    </Container>
  );
}

export default ItemsScreen;
