import React, { useState } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import UpdateDialog from "../Components/UpdateDialog";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CreateDialog from "../Components/CreateDialog";

function AreasScreen() {
  const [areas, setAreas] = useState([]);
  const [newArea, setNewArea] = useState({
    area: "",
    description: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);
  const [areaToUpdate, setAreaToUpdate] = useState({
    area: "",
    description: "",
  });

  const fields = [
    {
      label: "Area",
      name: "area",
      value: areaToUpdate.area,
      fullWidth: true,
      autoFocus: true,
      disabled: true,
    },

    {
      label: "Description",
      name: "description",
      value: areaToUpdate.description,
      style: { marginRight: "8px" },
    },
  ];

  const newfields = [
    {
      label: "Area",
      name: "area",
      value: newArea.area,
      fullWidth: true,
      autoFocus: true,
       
    },

    {
      label: "Description",
      name: "description",
      value: newArea.description,
      style: { marginRight: "8px" },
    },
  ];

  const fetchAreas = async (area) => {
    try {
      let response;
      if (area) {
        response = await axios.get(
          `https://localmap.space/api/location?location=${area}`
        );
      } else {
        response = await axios.get("https://localmap.space/api/areas");
      }
      setAreas(response.data);
    } catch (error) {
      console.error("Error fetching areas:", error);
    }
  };

  const handleUpdate = async () => {
    const { area, description } = areaToUpdate;
    const sql = `update areas set description = '${description}' where area = '${area}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });

      // Fetch only the updated item
      const response = await axios.get(
        `https://localmap.space/api/areas?area=${area}`
      );
      const updatedArea = response.data[0]; // Assuming only one item matches the updated item

      // Update the items state with the updated item
      const updatedAreas = areas.map((oldArea) =>
        oldArea.area === area ? updatedArea : oldArea
      );
      setAreas(updatedAreas);

      setUpdateDialogOpen(false); // Close the update dialog
      setSelectedRow(null); // Reset the selected row
      notify("Area has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating area:", error);
    }
  };

  const clearAreas = () => {
    notify("Areas cleared!", "success", 1000);
    setAreas([]);
    setSelectedRow(null); // Deselects any selected row
    setNewArea({
      area: "",
      description: "",
    }); // Clears the input fields
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewArea((prevArea) => ({
      ...prevArea,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { area, description } = newArea;
    const sql = `insert into areas (area, description) values ('${area}', '${description}')`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchAreas(); // Refresh the list after submitting the new item
      setNewArea({
        area: "",
        description: "",
      }); // Clear the input fields
      setCreateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error creating area:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const area = areas[selectedRow];
      setAreaToDelete(area);
      setOpenDialog(true);
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setAreaToUpdate((prevArea) => ({
      ...prevArea,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setAreaToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (areaToDelete) {
      const sql = `delete from areas where area = '${areaToDelete.area}'`;

      try {
        await axios.post("https://localmap.space/api/query", { sql });
        fetchAreas();
        setSelectedRow(null); 
      } catch (error) {
        console.error("Error deleting area:", error);
      } finally {
        setOpenDialog(false);
        setAreaToDelete(null);
      }
    }
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleModify = () => {
    if (selectedRow !== null) {
      const area = areas[selectedRow];
      setAreaToUpdate(area);
      setUpdateDialogOpen(true);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };
  const BoldHeader = styled("div")({
    fontWeight: "bold",

    textAlign: "center",
  });
  const CustomHeaderComponent = (props) => {
    return <BoldHeader>{props.colDef.headerName}</BoldHeader>;
  };

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Area"
          name="area"
          value={newArea.area}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchAreas(newArea.area)}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Find
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearAreas}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Clear
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleModify}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Modify
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Create
        </Button>
      </Box>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={areas.map((area, index) => ({
            ...area,
            id: index,
          }))}
          columns={[
            {
              field: "area",
              headerName: "Area",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },

            {
              field: "description",
              headerName: "Description",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
          ]}
          onRowClick={(params) => handleRowClick(params.id)}
          //checkboxSelection
          disableSelectionOnClick
          componentsProps={{ header: { style: { backgroundColor: "red" } } }}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {areaToDelete?.area}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <CreateDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        title="Create Area"
        contentText="Please provide Area:"
        fields={newfields}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        title="Update Area"
        fields={fields}
        handleInputChange={handleUpdateInputChange}
        handleSubmit={handleUpdate}
      />
    </Container>
  );
}

export default AreasScreen;
