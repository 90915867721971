import React, { useState,useEffect  } from "react";
import axios from "axios";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

function Home() {
  const [itemCount, setItemCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);

  useEffect(() => {
    axios.get("https://localmap.space/api/dashboard")
      .then(response => {
        const data = response.data;
        if (data.length > 0 && data[0].length > 0) {
          setItemCount(data[0][0].item_count);
          setLocationCount(data[0][0].location_count);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the item count!", error);
      });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop={8}
      padding={3}
      boxShadow={3}
      borderRadius={2}
      bgcolor="background.paper"
      width="100%"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {/* Small Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: 100, width: 200 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h5" component="div">
                  {itemCount}
                </Typography>
                <Typography variant="body2">Total Items</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Additional Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: 100, width: 200 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h5" component="div">
                  {locationCount}
                </Typography>
                <Typography variant="body2">
                  Total Locations
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: 100, width: 200 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h5" component="div">
                  Metric 3
                </Typography>
                <Typography variant="body2">
                  Description or value for Metric 3.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: 100, width: 200 }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h5" component="div">
                  Metric 4
                </Typography>
                <Typography variant="body2">
                  Description or value for Metric 4.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Big Card */}
        <Grid item xs={12}>
          <Card sx={{ height: 400 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Detailed Section
              </Typography>
              <Typography variant="body2">
                More detailed information or larger component can go here.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;