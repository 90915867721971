// ItemCreationDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from "@mui/material";

const LocationCreationDialog = ({
  open,
  onClose,
  newLocation,
  handleInputChange,
  handleSubmit
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Location</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide details for the new location:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Location"
          name="location"
          value={newLocation.location}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <TextField
          margin="dense"
          label="Area"
          name="area"
          value={newLocation.area}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <TextField
          margin="dense"
          label="Length"
          name="len"
          value={newLocation.len}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
        <TextField
          margin="dense"
          label="Width"
          name="wid"
          value={newLocation.wid}
          onChange={handleInputChange}
          size="small"
        />
        <TextField
          margin="dense"
          label="Height"
          name="hgt"
          value={newLocation.hgt}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
                <TextField
          margin="dense"
          label="Volume"
          name="volume"
          value={newLocation.volume}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
        <TextField
          margin="dense"
          label="Active (0 or 1)"
          name="active"
          value={newLocation.active}
          onChange={handleInputChange}
          size="small"
        />
        <TextField
          margin="dense"
          label="Storable"
          name="storable"
          value={newLocation.storable}
          onChange={handleInputChange}
          size="small"
        />
        <TextField
          margin="dense"
          label="Pickable"
          name="pickable"
          value={newLocation.pickable}
          onChange={handleInputChange}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationCreationDialog;
