import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { Button, Container, Box, Switch } from "@mui/material";

function SettingsScreen() {
  const [settings, setSettings] = useState([]);
  const [modifiedSettings, setModifiedSettings] = useState({});
  const [error, setError] = useState(null);

  const fetchSettings = async () => {
    try {
      const response = await axios.get("https://localmap.space/api/settings");
      setSettings(response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const BoldHeader = styled("div")({
    fontWeight: "bold",
    textAlign: "center",
  });

  const CustomHeaderComponent = (props) => {
    return <BoldHeader>{props.colDef.headerName}</BoldHeader>;
  };

  const handleToggle = (index, value) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting, i) =>
        i === index ? { ...setting, is_enabled: value } : setting
      )
    );
    setModifiedSettings((prevModifiedSettings) => ({
      ...prevModifiedSettings,
      [settings[index].name]: value,
    }));
  };

  const saveChanges = async () => {
    try {
      for (const [name, is_enabled] of Object.entries(modifiedSettings)) {
        const sql = `update settings set is_enabled=${is_enabled} where name='${name}'`;
        await axios.post("https://localmap.space/api/query", { sql });
      }
      setModifiedSettings({});
      fetchSettings(); // Refresh settings from the server
    } catch (error) {
      console.error("Error saving settings:", error);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="lg">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={saveChanges}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Save
        </Button>
      </Box>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={settings.map((setting, index) => ({
            ...setting,
            id: index,
          }))}
          columns={[
            {
              field: "name",
              headerName: "Name",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
            },
            {
              field: "is_enabled",
              headerName: "Enabled",
              flex: 1,
              renderHeader: (params) => <CustomHeaderComponent {...params} />,
              renderCell: (params) => (
                <Switch
                checked={params.value}
                onChange={(event) => handleToggle(params.row.id, event.target.checked)}
                onClick={(event) => event.stopPropagation()}
                />
              ),
            },
          ]}
          disableSelectionOnClick
          componentsProps={{ header: { style: { backgroundColor: "red" } } }}
        />
      </Box>
    </Container>
  );
}

export default SettingsScreen;
