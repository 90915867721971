import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const LocationUpdateDialog = ({ open, onClose, locationToUpdate, handleInputChange, handleUpdate }) => {
  if (!locationToUpdate) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Location</DialogTitle>
      <DialogContent>
        <TextField
          label="Location"
          name="location"
          value={locationToUpdate.location || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Area"
          name="area"
          value={locationToUpdate.area || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Length"
          name="len"
          value={locationToUpdate.len || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
        <TextField
          label="Width"
          name="wid"
          value={locationToUpdate.wid || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
        <TextField
          label="Height"
          name="hgt"
          value={locationToUpdate.hgt || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
         <TextField
          label="Volume"
          name="volume"
          value={locationToUpdate.volume || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
        <TextField
          label="Active"
          name="active"
          value={locationToUpdate.active || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
        <TextField
          label="Pickable"
          name="pickable"
          value={locationToUpdate.pickable || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
        <TextField
          label="Storable"
          name="storable"
          value={locationToUpdate.storable || ""}
          onChange={handleInputChange}
          style={{marginRight:"8px"}}
          margin="normal"
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleUpdate} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationUpdateDialog;
