import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const Notification = () => {
  return <NotificationContainer />;
};

export { Notification };
export const notify = (message, type = "info", duration = 1000) => {
  NotificationManager[type](message, "", duration);
};
