import React, { useState } from "react";
import axios from "axios";
import { Notification, notify } from "./Components/Notification";
import LocationCreationDialog from "./LocationCreationDialog";
import { DataGrid } from "@mui/x-data-grid";
import LocationUpdateDialog from "./LocationUpdateDialog";
import {
  Button,
  Container,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function LocationsScreen() {
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState({
    location: "",
    area: "",
    status: "",
    len: "",
    wid: "",
    hgt: "",
    volume: "",
    active: "",
    storable: "",
    pickable: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [locationToUpdate, setLocationToUpdate] = useState({
    location: "",
    area: "",
    status: "",
    len: "",
    wid: "",
    hgt: "",
    volume: "",
    active: "",
    storable: "",
    pickable: "",
  });

  const fetchLocations = async (location) => {
    try {
      let response;
      if (location) {
        response = await axios.get(
          `https://localmap.space/api/location?location=${location}`
        );
      } else {
        response = await axios.get("https://localmap.space/api/locations");
      }
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleUpdate = async () => {
    const {
      location,
      area,
      len,
      wid,
      hgt,
      volume,
      active,
      storable,
      pickable,
    } = locationToUpdate;
    const sql = `update locations set location = '${location}', area ='${area}', len = ${len}, wid = ${wid}, hgt = ${hgt}, volume = ${volume}, active = ${active}, storable = ${storable}, pickable = ${pickable} where location = '${location}'`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });

      // Fetch only the updated item
      const response = await axios.get(
        `https://localmap.space/api/locations?location=${location}`
      );
      const updatedLocation = response.data[0]; // Assuming only one item matches the updated item

      // Update the items state with the updated item
      const updatedLocations = locations.map((oldLocation) =>
        oldLocation.location === location ? updatedLocation : oldLocation
      );
      setLocations(updatedLocations);

      setUpdateDialogOpen(false); // Close the update dialog
      setSelectedRow(null); // Reset the selected row
      notify("Location has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  const clearLocations = () => {
    notify("Locations cleared!", "success", 1000);
    setLocations([]); // Clears the items from the screen
    setSelectedRow(null); // Deselects any selected row
    setNewLocation({
      location: "",
      area: "",
      status: "",
      len: "",
      wid: "",
      hgt: "",
      volume: "",
      active: "",
      storable: "",
      pickable: "",
    }); // Clears the input fields
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewLocation((prevLocation) => ({
      ...prevLocation,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const {
      location,
      area,
      len,
      wid,
      hgt,
      volume,
      active,
      storable,
      pickable,
    } = newLocation;
    const sql = `insert into locations (location, area, status, len, wid, hgt, volume, active, storable, pickable) values ('${location}', '${area}','Empty', ${len}, ${wid}, ${hgt},${volume}, ${active},${storable},${pickable})`;

    try {
      await axios.post("https://localmap.space/api/query", { sql });
      fetchLocations(); // Refresh the list after submitting the new item
      setNewLocation({
        location: "",
        area: "",
        status: "",
        len: "",
        wid: "",
        hgt: "",
        volume: "",
        active: "",
        storable: "",
        pickable: "",
      }); // Clear the input fields
      setCreateDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const location = locations[selectedRow];
      setLocationToDelete(location);
      setOpenDialog(true);
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setLocationToUpdate((prevLocation) => ({
      ...prevLocation,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setLocationToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (locationToDelete) {
      const sql = `delete from locations where location = '${locationToDelete.location}'`;

      try {
        await axios.post("https://localmap.space/api/query", { sql });
        fetchLocations(); // Refresh the list after deleting the item
        setSelectedRow(null); // Reset the selected row
      } catch (error) {
        console.error("Error deleting location:", error);
      } finally {
        setOpenDialog(false);
        setLocationToDelete(null);
      }
    }
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleModify = () => {
    if (selectedRow !== null) {
      const location = locations[selectedRow];
      setLocationToUpdate(location);
      setUpdateDialogOpen(true);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  return (
    <Container maxWidth="lx">
      {/* Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Location"
          name="location"
          value={newLocation.location}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchLocations(newLocation.location)}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Find
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={clearLocations}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Clear
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleModify}
          disabled={selectedRow === null}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Modify
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Create
        </Button>
      </Box>

      {locations.length > 0 && (
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={locations.map((location, index) => ({
              ...location,
              id: index,
            }))}
            columns={[
              { field: "location", headerName: "Location", flex: 1 },
              { field: "area", headerName: "Area", flex: 1 },
              { field: "len", headerName: "Length", flex: 1 },
              { field: "wid", headerName: "Width", flex: 1 },
              { field: "hgt", headerName: "Height", flex: 1 },
              { field: "volume", headerName: "Volume", flex: 1 },
              { field: "active", headerName: "Active", flex: 1 },
              { field: "storable", headerName: "Storable", flex: 1 },
              { field: "pickable", headerName: "Pickable", flex: 1 },
            ]}
            onRowClick={(params) => handleRowClick(params.id)}
            //checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      )}

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {locationToDelete?.location}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <LocationCreationDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        newLocation={newLocation}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />
      <LocationUpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        locationToUpdate={locationToUpdate}
        handleInputChange={handleUpdateInputChange}
        handleUpdate={handleUpdate}
      />
    </Container>
  );
}

export default LocationsScreen;
